<template>
  <v-card>
    <v-card-text>
      Speedtest
      </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>